import { useState, useEffect } from 'react'
import FailCard from './FailCard'
import '../styles/FailList.css'

const API_URL = 'https://top500fails-api.web.app' // 'http://127.0.0.1:5002'

// https://firebasestorage.googleapis.com/v0/b/top500fails.appspot.com/o/0.jpg?alt=media

export default function FailList() {
  const [failures, setFailures] = useState()
  useEffect(() => {
    fetch(`${API_URL}/fails`)
      .then(r=>r.json())
      .then(setFailures)
      .catch(alert)
  }, [])
  return (
    <main>
      <article className='fail-list'>
        {!failures
          ? <h2>Loading...</h2>
          : failures.map(fail => <FailCard key={fail.id} fail={fail} />)
        }
        <p>More to come...</p>
      </article>
    </main>
  )
}