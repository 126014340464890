import { Card } from "antd";

export default function FailCard({ fail }) {
  return (
    <Card
      className="fail-card"
      hoverable
      // style={{ width: 240 }}
      cover={
        <img
          alt={fail.title}
          src={fail.image}
        />
      }
    >
      <Card.Meta title={fail.title} description={fail.description} />
    </Card>
  );
}
