import { ConfigProvider, theme, Layout } from "antd";
import "./App.css";
import "antd/dist/reset.css";
import FailList from "./components/FailList";

function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#EB2F96",
          colorSuccess: "#13C2C2",
          colorInfo: "#1677ff",
          fontSize: 15,
          wireframe: false,
        },
      }}
    >
      <div className="App">
        <header className="App-header">
          <Layout.Header className="header">
            <h1 className="logo">Top 500 Fails</h1>
          </Layout.Header>
          <FailList />
        </header>
      </div>
    </ConfigProvider>
  );
}

export default App;
